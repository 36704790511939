/*2023*/

.card-pro {
  display: grid;
  place-items: center;
}

.card-projects {
  width: 310px;
  height: 260px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  justify-content: center;
  background-color: white;
  z-index: 9999;
}

.card-projects img {
  position: absolute;
  size: cover;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.4s;
}

.btn1 {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 30px;
  height: 30px;
  z-index: 100;
  appearance: none;
}

.btn1::before {
  content: "i";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  color: white;
  background: black;
  cursor: pointer;
  transition: all 0.4s;
}

.btn1:checked::before {
  content: "X";
  position: absolute;
  background: white;
  color: black;
}

.overlay {
  position: absolute;
  right: -80%;
  bottom: -80%;
  width: 310px;
  height: 260px;
  background: rgba(235, 0, 40, 0.9);
  border-radius: 5px;
  transition: all 0.4s;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.498);
  color: rgb(255, 255, 255);
  padding: 60px 10px 0 25px;
}

.overlay p {
  font-size: 12px;
  font-family: GothamBook;
}

.btn1:checked ~ .overlay {
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 310px;
  height: 260px;
}

/* edizione 2022 */
.portfolio-item {
  background-color: white;
}

/* 2024 */

/* partners 24 single mobile div */
.mobile-div {
  padding: 5vh 5vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
}

/* partners 24 text div within a single mobile div */
.mobile-text-block {
  font-size: 15px;
  color: white;
  text-align: left;
}

.info-list-number,
.red-txt {
  font-weight: bold;
  color: #eb0028;
}

.info-list-element {
  padding: 2vh 0;
}

/* mobile + tablet */
@media screen and (max-width: 820px) {
  .info-list-number {
    font-size: 4vh;
  }
}

/* PartnerCard24 */
.card-main-container {
  position: relative;
  width: 230px;
  height: 230px;
  color: white;
  background-color: #191919;
  margin: 0px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
}

.card-inner-container {
  width: 100%;
  height: 90%;
  background-color: white;
  border-radius: 10%;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transform-style: preserve-3d;
  transition: all 0.8s ease;
}

.card-inner-container:hover {
  transform: rotateY(180deg);
}

.card-front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  overflow: hidden;
  background: white;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  overflow: hidden;
  background: white;
  color: #333;
  text-align: center;
  font-size: 13px;
  padding: 5px 7px;
  transform: rotateY(180deg);
}

.sponsor_logo {
  width: 100%;
  height: 175px;
  object-fit: contain;
  padding: 0px 5px;
  border-radius: 10%;
}

.sponsor_name {
  font-size: 20px;
  font-weight: bold;
}

/* PartnersList24 */
#external_div {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

#title_div {
  color: white;
  margin-top: 95px;
}

/*#partners_div {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 35px 0;
  height: auto;
  width: 90%; /* 110% to match footer's width */
/*}*/
